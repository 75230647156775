<template>
  <div class="w-full">
    <div
      class="mb-6 flex w-full flex-col items-center lg:flex-row lg:items-end"
    >
      <div
        class="flex w-4/5 flex-col items-center py-2 text-g7-blue sm:w-1/2 sm:items-end sm:py-0 lg:w-full lg:flex-row"
      >
        <div class="my-2 w-full sm:my-0 lg:mr-8">
          <FieldOceanRiverToggler v-model="cruiseType" name="cruisetype" />
          <FieldComboboxByValue
            v-model="destination"
            :items="destinations?.options"
            :disabled="pending"
          />
        </div>

        <FieldComboboxByValue
          v-model="cruiseline"
          class="my-2 w-full sm:my-0 lg:mr-8"
          :items="cruiselines?.options"
          :disabled="pending"
        />
        <FieldDatepickerRange
          v-model="date"
          placeholder="Zeitraum & Dauer"
          :durations="durations"
          class="my-2 w-full sm:my-0 lg:mr-8"
          @set-duration="setDuration"
        />
      </div>

      <G7Button
        class="h-field w-4/5 px-2.5 sm:w-1/2 lg:ml-4 lg:mr-10 lg:w-auto"
        :label="searchOptions?.search_filter_data_fields.action_button.label"
        @click="search"
      />

      <NuxtLink
        class="hidden lg:inline"
        :to="`/${searchOptions?.search_filter_data_fields.action_link.context}`"
      >
        <div
          class="flex cursor-pointer items-center justify-center rounded-md p-2 hover:bg-teal/50"
        >
          <span class="text-3xl text-white underline">
            {{ searchOptions?.search_filter_data_fields.action_link.label }}
          </span>
        </div>
      </NuxtLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { CruiseFinderResponse } from "~~/types/response";
import type { DateRange } from "~~/types/form";
import type { CruiseFinder } from "~/types/search";

const props = defineProps<{
  links?: string;
}>();

const pending = ref(false);
const cruiseType = ref("hochsee");
const { data: searchOptions } = await useFetch<CruiseFinderResponse>(
  props.links ??
    apiUrl(`/catalog/homepages/go7seas/cruise-finder?cruiseregion=hochsee`)
);

const dataFields = searchOptions.value?.search_filter_data_fields;
const destinations = ref(dataFields?.cruise_region_selection);
const cruiselines = ref(dataFields?.cruise_line_selection);
const durations = ref(dataFields?.duration_range_selection);

const destination = ref(destinations?.value?.value ?? "-");
const cruiseline = ref(cruiselines?.value?.value ?? "-");
const duration = ref(durations?.value?.value ?? null);
const date: Ref<DateRange> = ref({
  start: dataFields?.arrival_date_field?.value ?? "",
  end: dataFields?.departure_date_field?.value ?? "",
});

function getSearchOptions(
  key: string,
  value: string
): Promise<CruiseFinderResponse> {
  return $fetch<CruiseFinderResponse>(
    apiUrl(`/catalog/homepages/go7seas/cruise-finder?${key}=${value}`),
    { headers: defaultApiHeaders() }
  );
}

function search() {
  const region =
    destination.value === "-" ? cruiseType.value : destination.value;
  const range =
    (date.value.start ? `/abreise/${date.value.start}` : "") +
    (date.value.end ? `/rueckkehr/${date.value.end}` : "") +
    (duration.value ? `/dauer/${duration.value}` : "");
  const url = `/suche/reederei/${cruiseline.value}/region/${region}${range}`;
  useRouter().push(url);
}

function setDuration(event) {
  if (event !== null) {
    duration.value = event.value;
  } else {
    duration.value = null;
  }
}

watch(cruiseType, async () => {
  pending.value = true;
  const searchOptions = await getSearchOptions(
    "cruiseregion",
    cruiseType.value
  );
  setValues(searchOptions.search_filter_data_fields);

  destination.value = destinations.value?.value ?? "-";
  cruiseline.value = cruiselines.value?.value ?? "-";

  pending.value = false;
});

watch(cruiseline, async () => {
  pending.value = true;
  const searchOptions = await getSearchOptions("cruiseline", cruiseline.value);
  setValues(searchOptions.search_filter_data_fields);
  pending.value = false;
});

watch(destination, async () => {
  pending.value = true;
  const searchOptions = await getSearchOptions(
    "cruiseregion",
    destination.value
  );
  setValues(searchOptions.search_filter_data_fields);
  pending.value = false;
});

function setValues(dataFields: CruiseFinder) {
  destinations.value = dataFields.cruise_region_selection;
  cruiselines.value = dataFields.cruise_line_selection;
  durations.value = dataFields.duration_range_selection;
  duration.value = durations?.value?.value ?? null;

  date.value = {
    start: dataFields.arrival_date_field.value ?? "",
    end: dataFields.departure_date_field.value ?? "",
  };
}
</script>
