<template>
  <div
    class="flex size-full flex-col justify-between border-2 border-solid border-inherit text-g7-blue"
  >
    <NuxtLink :to="item.image.context">
      <div class="aspect-h-9 aspect-w-16 relative">
        <div style="background-color: #a4cadd">
          <CloudinaryImg
            :public-id="item.image.public_id"
            :steps="[200, 400, 600]"
            class="size-full object-cover"
          />
          <div
            class="absolute top-0 z-10 mx-4 my-2 size-min whitespace-nowrap rounded-md bg-g7-blue px-1 text-white sm:bottom-0 sm:top-auto"
          >
            <span class="text-xl" v-html="`${duration[0]} `" />
            <span v-html="duration[1]" />
          </div>
          <CloudinaryImg
            :public-id="item.cruise_line_logo.public_id"
            :steps="[200, 400]"
            class="absolute right-0 top-0 mx-4 my-2 h-5 cursor-pointer sm:bottom-0 sm:top-auto sm:h-8"
          />
        </div>
      </div>
    </NuxtLink>
    <div class="mx-2 mt-1 grow text-left sm:mx-4 sm:mt-2">
      <Headline class="normal-case" :title="item.title" />
    </div>
    <div class="mx-2 text-left sm:mx-4 sm:mt-2">
      <Headline
        :h="3"
        size="XL"
        class="font-black normal-case"
        :title="item.ship_name"
      />
      <span class="font-medium" v-html="item.region_name" />
    </div>
    <div class="flex items-center px-1">
      <IconsCalendar class="size-6 fill-g7-blue p-1 sm:size-11 sm:p-3" />
      <span class="text-sm font-bold decoration-transparent sm:text-base">
        {{ item.date_line }}
      </span>
    </div>

    <hr class="mx-2 sm:mx-4" />

    <div class="flex flex-row justify-between p-2 sm:p-4">
      <div class="flex h-full grow items-center uppercase">
        <div class="mr-1 size-6 sm:size-10 3xl:size-8">
          <IconsShip
            class="size-6 rounded-full bg-teal fill-white p-1 sm:size-10 sm:p-2 3xl:size-8"
          />
        </div>
        <div
          v-if="item.includes_hotel"
          class="mr-1 size-6 sm:size-10 3xl:size-8"
        >
          <IconsBed
            class="size-6 rounded-full bg-teal fill-white p-1 sm:size-10 sm:p-2 3xl:size-8"
          />
        </div>
        <div
          v-if="item.includes_flight"
          class="mr-1 size-6 sm:size-10 3xl:size-8"
        >
          <IconsPlane
            class="size-6 rounded-full bg-teal fill-white p-1 sm:size-10 sm:p-2 3xl:size-8"
          />
        </div>
      </div>
      <div class="flex flex-col items-end justify-end text-right 3xl:flex-row">
        <span class="text-sm decoration-transparent sm:text-base 3xl:text-sm">
          {{ item.price.context }} {{ item.price.prefix }}
        </span>

        <span
          class="ml-1 text-base sm:text-2xl"
          v-html="item.price.formatted"
        />
      </div>
    </div>

    <G7Button
      class="py-1"
      :label="item.action.label"
      :to="item.action.context"
      data-cy="start"
    />
  </div>
</template>

<script lang="ts" setup>
import type { CruiseTopOffer } from "~~/types/cruise";

const props = defineProps<{
  item: CruiseTopOffer;
}>();
const duration = props.item.duration.split(" ");
</script>
